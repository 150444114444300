/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LogoDisplay from '../components/logoDisplay';
import ContactPageLayout from '../components/contactPageLayout';
import PropTypes from 'prop-types';
import TopMenu from '../components/topMenu';




const FunPerson = ({ color }) => {

    const subtitleContent = (
        
    <div className="text-md">
      <p>
      Like a high performance concept car, you’ve known you need to shift gears to achieve great things. We’re looking for people who totally rock to partner with for an opportunity that can move the needle and do it fast. We’ll get you set on an incredibly lucrative plan. Vibe on!
      </p>
    </div>
  );
    
  return (
    <Layout to="/connect" label="WHICH IMAGE?" showMenuIcon>
      <SEO title="Vivo Vibe" />
      <TopMenu></TopMenu>
      <section className="h-screen w-screen red-bg-img">

      <ContactPageLayout
        color={color}
        title="We’re looking for power brokers"
        titleClassName="bg-directions"
        subTitle={subtitleContent}
        subtitleContent={subtitleContent}
      ></ContactPageLayout>
      </section>
      </Layout>
  );
};

FunPerson.propTypes = {
  color: PropTypes.string,
};

FunPerson.defaultProps = {
  color: 'fun',
};
export default FunPerson;
